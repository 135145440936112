<template>
  <b-card-code title="Edit User" class="edit-user">
    <b-row class="d-flex justify-content-center">
      <b-col cols="8">
        <validation-observer ref="userRules">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Name" label-for="name" label-cols-md="4">
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      placeholder="Name"
                      v-model="user.name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-group
                    label="Email"
                    label-for="email"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="email"
                      type="email"
                      placeholder="Email"
                      v-model="user.email"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- password -->
              <b-col cols="12">
                <b-form-group
                  label="Password"
                  label-for="a-password"
                  description="Your password must contain at least one uppercase, one lowercase, one special character, one digit and be 8+ characters long."
                  label-cols-md="4"
                >
                  <b-form-input
                    id="a-password"
                    v-model="user.password"
                    type="password"
                    placeholder="Password"
                  />
                </b-form-group>
              </b-col>
              <b-col offset-md="4" cols="8">
                <span class="text-danger"
                  >(&#x203B; If you put in a password, it will reset the user's
                  password to that value.)</span
                >
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Admin privilege"
                  label-for="is_admin"
                  label-cols-md="4"
                >
                  <b-form-checkbox
                    checked="true"
                    name="is_admin"
                    switch
                    inline
                    v-model="user.is_admin"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Super Exporter"
                  label-for="is_superexporter"
                  label-cols-md="4"
                >
                  <b-form-checkbox
                    checked="true"
                    name="is_superexporter"
                    switch
                    inline
                    v-model="user.is_superexporter"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <!-- PERMISSION TABLE -->
                <b-card no-body class="border mt-1">
                  <b-card-header class="p-1">
                    <b-card-title class="font-medium-2">
                      <feather-icon icon="LockIcon" size="18" />
                      <span class="align-middle ml-50">Role</span>
                    </b-card-title>
                  </b-card-header>

                  <b-row class="mx-2">
                    <b-col cols="5">
                      <b-form-group
                        label="Hospital"
                        label-for="hospital"
                        label-cols-md="3"
                      >
                        <v-select
                          v-model="hospital"
                          label="hospital_id"
                          :options="hospitals"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="5">
                      <b-form-group
                        label="Role"
                        label-for="role"
                        label-cols-md="3"
                      >
                        <v-select
                          v-model="role"
                          label="value"
                          :options="optionRoles"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="2">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success"
                        @click="addRole"
                        >Add Role</b-button
                      >
                    </b-col>
                    <b-col cols="12">
                      <b-table :fields="fields" :items="roles" responsive>
                        <!-- A virtual column -->
                        <template #cell(index)="data">
                          {{ data.index + 1 }}
                        </template>

                        <!-- A custom formatted column -->
                        <template #cell(hospital_id)="data">
                          {{ data.value }}
                        </template>

                        <template #cell(role)="data">
                          {{ data.value }}
                        </template>

                        <!-- Optional default data cell scoped slot -->
                        <template #cell(action)="data">
                          <b-button variant="danger" size="sm"
                            ><feather-icon
                              icon="TrashIcon"
                              @click="deleteRole(data.index)"
                          /></b-button>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>

              <!-- submit and reset -->
              <b-col offset-md="4">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="secondary"
                  class="mr-1"
                  @click="$router.push({ name: 'admin-users' })"
                >
                  Back
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  class="mr-1"
                >
                  Reset
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  @click.prevent="handleAdd"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode1.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormTextarea,
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email, min, confirmed } from "@validations";
import vSelect from "vue-select";

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: {
        name: "",
        email: "",
        password: "",
        is_admin: false,
        is_superexporter: false,
      },
      required,
      email,
      min,
      confirmed,
      roles: [],
      role: "",
      hospital: "",
      optionRoles: [
        { value: "exporter" },
        { value: "editor" },
        { value: "viewer" },
      ],
      hospitals: [],
      fields: [
        "index",
        { key: "hospital_id", label: "Hospital ID" },
        { key: "role", label: "Role" },
        "Action",
      ],
    };
  },
  mounted() {
    this.fetchUser();
    this.fetchHospitals();
  },
  methods: {
    fetchHospitals() {
      this.$http
        .get("/admin/hospitals/list_for_select")
        .then((res) => {
          this.hospitals = res.data.hospitals;
        })
        .catch((err) => {
          this.showToast(
            "danger",
            "Error",
            "An error occured while fetching hospitals."
          );
        });
    },
    fetchUser() {
      this.$http
        .get(`/admin/users/read/${this.$route.params.id}`)
        .then((res) => {
          this.user = res.data.user;
          this.roles = res.data.roles;
        })
        .catch((err) => {
          this.showToast(
            "danger",
            "Error",
            "An error occured while reading a user."
          );
        });
    },
    handleAdd() {
      this.$refs.userRules.validate().then((success) => {
        if (success) {
          this.user.roles = this.roles;
          this.$http
            .patch(`/admin/users/update/${this.$route.params.id}`, this.user)
            .then((res) => {
              this.showToast("success", "Success", "Successfully Updated.");
              this.$router.push({ name: "admin-users" });
            })
            .catch((err) => {
              this.showToast(
                "danger",
                "Error",
                "An error occured while updating a user."
              );
            });
        }
      });
    },
    showToast(variant, title, content) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: content,
          variant,
        },
      });
    },
    addRole() {
      if (
        !this.role ||
        !this.role.value ||
        !this.hospital ||
        !this.hospital.hospital_id
      ) {
        return;
      }

      let obj = {};
      if (this.role && this.role.value) {
        obj.role = this.role.value;
      }
      if (this.hospital && this.hospital.hospital_id) {
        obj.hospital_id = this.hospital.hospital_id;
        obj.hospital_id_ = this.hospital._id;
      }
      this.role = "";
      this.hospital = "";
      if (
        this.roles.some(
          (item) => item.role == obj.role && item.hospital_id == obj.hospital_id
        )
      ) {
        this.showToast("warning", "Warning", "Already existing role.");
        return;
      }
      this.roles.push(obj);
    },
    deleteRole(index) {
      this.roles.splice(index, 1);
    },
  },
};
</script>
<style lang="scss">
.edit-user {
  .form-group {
    .col {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
</style>